import React from 'react'
import Layout from '../components/Layout'
import { ChevronRightIcon, StarIcon, ExternalLinkIcon } from '@heroicons/react/solid'
import { Devtools } from '@ui-devtools/tailwind'
import { Link } from 'gatsby'

const stats = [
    { label: 'Black Woman Owned', value: '100%' },
    { label: 'Team Experience', value: '25 yrs' },
    { label: 'High-Impact Projects', value: '35+' },
]
const vision = [
    { id: 1, title: 'For Our Customers', detail: 'Peace of mind at every interaction, we collaborate closely with our customers to understand their needs and context, blending this with expert advise and solution recommendation. We constantly strive to empower and innovate, resulting in fair value exchange between us and our customers.' },
    { id: 2, title: 'For Our Employees', detail: 'As an employer who values the dedication and commitment of its employees; we ensure the safety of our employees and champion a safe working environment backed by product safety training.' },
    { id: 3, title: 'For Our Suppliers', detail: 'We push our suppliers and ourselves to achieve and maintain a spirit of collaboration, mutual respect, amicable and ethical engagements all ultimately at the service of Virgin Transformers’ customers.' },
    { id: 4, title: 'For Our Industry', detail: 'Partnerships and sustainability are essential in building a sustainable business, manufacturing of quality products, and providing excellent services.' },
]

export default function About() {
    return (
        <div className="min-h-screen bg-grey-100">
            <Devtools>
                <Layout>
                    <main>
                        <section id="about-header">
                            <div className="relative bg-red-500">
                                <div className="absolute inset-0">
                                    <img
                                        className="object-cover w-full h-full"
                                        src="https://res.cloudinary.com/minimum-viable/image/upload/q_49/v1621340761/virgintransformers/transformer-focus_hj0ckf.jpg"
                                        alt=""
                                    />
                                    <div className="absolute inset-0 bg-red-500 mix-blend-multiply" aria-hidden="true" />
                                </div>
                                <div className="relative px-4 py-24 mx-auto max-w-7xl sm:py-32 sm:px-6 lg:px-8">
                                    <h1 className="text-4xl font-medium tracking-tight text-center text-white border-white sm:text-5xl lg:text-6xl">About Us</h1>

                                </div>
                            </div>
                        </section>
                        <section id="about">
                            <div className="bg-white">
                                <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8 sm:py-24">
                                    <div className="text-center">
                                        <h2 className="pb-4 text-base font-medium tracking-wide text-red-600 uppercase">About virgin transformers</h2>

                                        <p className="max-w-xl mx-auto mt-5 text-xl text-gray-500">
                                            Virgin Transformers is a world class manufacturer, repairer and distributor of quality electrical equipment to the South African market. Our team comprises highly experienced and technically skilled personnel who take pride in promoting value-based solutions to our clients.
          </p>
                                        <Link to="/what-we-do">
                                            <button className="px-5 py-3 mt-10 text-base font-medium text-white uppercase bg-red-700 border border-transparent rounded-md shadow hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:px-10"
                                            >
                                                What We Do
                  </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id="vision">

                            <div className="relative bg-gray-900">
                                <div className="absolute bottom-0 w-full h-80 xl:inset-0 xl:h-full">
                                    <div className="w-full h-full xl:grid xl:grid-cols-2">
                                        <div className="h-full xl:relative xl:col-start-2">
                                            <img
                                                className="object-cover w-full h-full opacity-25 xl:absolute xl:inset-0"
                                                src="https://res.cloudinary.com/minimum-viable/image/upload/v1621340758/virgintransformers/blue-power_nppwvu.jpg"
                                                alt="People working on laptops"
                                            />
                                            <div
                                                aria-hidden="true"
                                                className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="max-w-4xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
                                    <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
                                        <h2 className="text-sm font-semibold tracking-wide text-white uppercase">Our Vision</h2>
                                        <p className="mt-3 text-3xl font-medium text-white">
                                            Many hands make light work
          </p>
                                        <p className="mt-5 text-lg text-gray-300">
                                            Virgin Transformers firmly believes in the power of collaboration and partnerships as a key driver of our vision to be the transformer and electrical components supplier of choice to our discerning clients.
          </p>
                                        <div className="grid grid-cols-1 mt-12 gap-y-12 gap-x-6 sm:grid-cols-2">
                                            {vision.map((item) => (
                                                <p key={item.id}>
                                                    <span className="block text-2xl font-medium text-white">{item.title}</span>
                                                    <span className="block mt-1 text-base text-gray-300">
                                                        {item.detail}
                                                    </span>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="main-content">
                            <div className="relative py-16 bg-white sm:py-24">
                                <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                                    <div className="relative sm:py-16 lg:py-0">
                                        <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                            <div className="absolute inset-y-0 w-full right-1/2 bg-gray-50 rounded-r-3xl lg:right-72" />
                                            <svg
                                                className="absolute -ml-3 top-8 left-1/2 lg:-right-8 lg:left-auto lg:top-12"
                                                width={404}
                                                height={392}
                                                fill="none"
                                                viewBox="0 0 404 392"
                                            >
                                                <defs>
                                                    <pattern
                                                        id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                                        x={0}
                                                        y={0}
                                                        width={20}
                                                        height={20}
                                                        patternUnits="userSpaceOnUse"
                                                    >
                                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                                    </pattern>
                                                </defs>
                                                <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                            </svg>
                                        </div>
                                        <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                            {/* Testimonial card*/}
                                            <div className="relative pt-64 pb-10 overflow-hidden rounded shadow-xl">
                                                <img
                                                    className="absolute inset-0 object-cover w-full h-full"
                                                    src="https://images.unsplash.com/photo-1521510895919-46920266ddb3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&fp-x=0.5&fp-y=0.6&fp-z=3&width=1440&height=1440&sat=-100"
                                                    alt=""
                                                />
                                                <div className="absolute inset-0 bg-red-500 mix-blend-multiply" />
                                                <div className="absolute inset-0 bg-gradient-to-t from-red-600 via-red-600 opacity-90" />
                                                <div className="relative px-8">

                                                    <blockquote className="mt-8">
                                                        <div className="relative text-lg font-medium text-white md:flex-grow">
                                                            <svg
                                                                className="absolute top-0 left-0 w-8 h-8 text-red-400 transform -translate-x-3 -translate-y-2"
                                                                fill="currentColor"
                                                                viewBox="0 0 32 32"
                                                                aria-hidden="true"
                                                            >
                                                                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                            </svg>
                                                            <p className="relative">
                                                                As a 100% Black Female owned entity, we take our role in the transformation of the electrical manufacturing and supply industry extremely seriously. With every interaction we challenge the prevailing attitudes and barriers to meaningful participation.
                    </p>
                                                        </div>

                                                        <footer className="mt-4">
                                                            <p className="text-base font-semibold text-red-200">Lindiwe Buthelezi, CEO at Virgin Transformers</p>
                                                        </footer>
                                                    </blockquote>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
                                        {/* Content area */}
                                        <div className="pt-12 sm:pt-16 lg:pt-20">
                                            <h2 className="text-3xl font-medium tracking-tight text-gray-600 sm:text-4xl">
                                                On a mission to empower organizations
            </h2>
                                            <div className="mt-6 space-y-6 text-gray-500">
                                                <p className="text-lg">
                                                    We leverage our partnerships with suppliers and clients to deliver the maximum in fair value exchange; ensuring high quality products and workmanship become our calling card.
              </p>
                                                <p className="text-base leading-7">
                                                    We achieve this through attention to detail and a personal touch that leaves our clients and suppliers bowled over. We further endeavor to empower
                                                    and transfer skills to previously disadvantaged members of our population.
              </p>

                                            </div>
                                        </div>

                                        {/* Stats section */}
                                        <div className="mt-20">
                                            <dl className="grid grid-cols-3 gap-x-4 gap-y-8">
                                                {stats.map((stat) => (
                                                    <div key={stat.label} className="pt-6 border-t-2 border-gray-100">
                                                        <dd className="text-3xl font-medium tracking-tight text-red-500">{stat.value}</dd>
                                                        <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                                                    </div>
                                                ))}
                                            </dl>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="CTA">
                            <div className="relative bg-red-500">
                                <div className="h-56 bg-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                                    <img
                                        className="object-cover w-full h-full"
                                        src="https://res.cloudinary.com/minimum-viable/image/upload/c_scale,q_51,w_2000/v1621340767/virgintransformers/power-caution_knaluc.jpg"
                                        alt=""
                                    />
                                </div>
                                <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16">
                                    <div className="md:ml-auto md:w-1/2 md:pl-10">
                                        <h2 className="text-base font-medium tracking-wider text-white uppercase">virgin transformers - more power to you</h2>
                                        <p className="mt-2 text-3xl font-medium tracking-tight text-white sm:text-4xl">We’re here to help</p>
                                        <p className="mt-3 text-lg text-white">
                                            We would love to chat to you about your electrical components and transformer needs.
          </p>
                                        <div className="mt-8">
                                            <div className="inline-flex rounded-md shadow">
                                                <a
                                                    href="#"
                                                    className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                                                >
                                                    Get in touch
                <ExternalLinkIcon className="w-5 h-5 ml-3 -mr-1 text-gray-400" aria-hidden="true" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>


                </Layout>
            </Devtools>
        </div >
    );
}
